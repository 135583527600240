<template>
    <layout class="leaderboard" :loading="loading">
        <template #header>
          <div class="d-flex align-items-center">
            <h1 class="title-1">Balance Details</h1>
            <router-link to="/about/bimi-coins" type="button" class="leaderboard__info btn btn-secondary btn-icon btn-sm ml-3 p-0" v-b-tooltip.hover title="Read more about Bimi Coins">
              <i class='bx bx-question-mark font-size-20'></i>
            </router-link>
          </div>
        </template>
        <ModalForm
                :modalId="modalId"
                :formMode="formMode"
                :initialData="currentRow"
                :modalTitle="modalTitle"
                @onSubmit="handleFormSubmit"
        />
        <div class="d-flex align-items-center justify-content-end mb-2">
            <div class="balance d-flex align-items-center">
                <span class="amount m-0">{{ balance ? balance : 0 }}</span>
                <span class="ml-2">Bimi Coins - your current balance</span>
            </div>
            <div v-if="balance">
                <b-button
                        class="ml-3"
                        variant="primary"
                        @click="handleAdd()"
                >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    Add request
                </b-button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body p-0">
                        <table v-if="list.length" class="table mb-0">
                            <thead>
                            <th style="width: 50px">#</th>
                            <th class="text-left">Type</th>
                            <th>Date</th>
                            <th class="text-right">Amount</th>
                            <th class="text-left">Status</th>
                            <th style="width: 50px">Actions</th>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in list" :key="item.id">
                                <td>{{index + 1}}</td>
                                <td class="text-left">{{item.transaction_type ? item.transaction_type.title : ''}}</td>
                                <td>{{moment(item.created_at).format('DD.MM.YYYY')}}</td>
                                <td class="text-right"><span v-if="item.type_id == 9">-</span>{{item.amount || 0}}</td>
                                <td class="text-left">
                                    <span :class="`badge bg-`+badge(item.status_id)">{{item.transaction_status ? item.transaction_status.title : ''}}</span>
                                </td>
                                <td>
                                    <div class="d-flex justify-content-center" v-if="item.type_id != 10 && item.type_id != 11 && item.type_id != 12">
                                        <b-button
                                                style="margin-top: -0.5rem;"
                                                variant="outline-primary"
                                                class="shadow-none border-0 p-2 mt--2 edit-btn"
                                                @click="handleEdit(item)"
                                        >
                                            <span v-if="item.status_id === 1" class="fa fa-pen"></span>
                                            <span v-else class="fa fa-eye"></span>
                                        </b-button>
                                        <b-button
                                                style="margin-top: -0.5rem;"
                                                variant="outline-danger"
                                                class="shadow-none border-0 p-2 mt--2 edit-btn"
                                                @click="handleDelete(item.id)"
                                                v-if="item.status_id === 1"
                                        >
                                            <span class="fa fa-trash"></span>
                                        </b-button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else class="font-size-16 text-muted"><em>You don't have any requests yet</em></div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>
<script>

    import {FORM_MODE} from "@/utils/constants";
    import {convertObjectToFormData} from "@/utils/converters";
    //import ModalForm from '@/components/modal-coin-request';
    import ModalForm from './balance-details-modal';
    import moment from 'moment';

    /**
     * Editors component
     */
    export default {
        components: {
            ModalForm
        },
        data() {
            return {
                leaderboards: [],
                modalId: 'request-modal',
                modalTitle: '',
                currentRow: null,
                formMode: '',
                moment
            };
        },
        mounted() {
            this.$store.dispatch('profile/getProfile');
            this.$store.dispatch('balanceDetails/getRequests');
            this.$store.dispatch('balanceDetails/getBalanceRequest', this.user?.user?.uid);
        },
        methods: {
            handleAdd() {
                this.modalTitle = 'Bimi Coin Request';
                this.currentRow = null;
                this.formMode = FORM_MODE.CREATE;
                this.$bvModal.show(this.modalId);
            },
            handleEdit(row) {
                this.currentRow = row;
                this.formMode = FORM_MODE.EDIT;
                this.modalTitle = 'Bimi Coin Request';
                this.$bvModal.show(this.modalId);
            },
            handleDelete(id) {
                this.$bvModal.msgBoxConfirm('Delete this item?', {
                    title: 'Please Confirm',
                    okVariant: 'danger',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(async (value) => {
                        if (value) {
                            await this.$store.dispatch('balanceDetails/deleteRequest', {
                                data: convertObjectToFormData({_method: 'DELETE'}),
                                id
                            });
                            await this.$store.dispatch('balanceDetails/getRequests');
                        }
                    });
            },
            async handleFormSubmit(data) {
                await this.$store.dispatch('global/changeLoadingValue', true);
                if (this.formMode === FORM_MODE.CREATE) {
                    await this.$store.dispatch('balanceDetails/createRequest', convertObjectToFormData(data));
                }
                if (this.formMode === FORM_MODE.EDIT) {
                    await this.$store.dispatch('balanceDetails/updateRequest', {
                        id: this.currentRow.id,
                        data: convertObjectToFormData({
                            ...data,
                            //_method: 'PUT'
                        })
                    });
                }
                if(data.type_id == 10){
                    this.$store.dispatch('balanceDetails/getBalanceRequest', this.user?.user?.uid);
                }
                await this.$store.dispatch('global/changeLoadingValue', true);
                await this.$store.dispatch('balanceDetails/getRequests');
                this.$bvModal.hide(this.modalId);
            },
            badge(id) {
                return id == 1 ? 'warning' : id == 2 ? 'success' : 'danger'
            }
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
            balance() {
                return this.$store.getters['balanceDetails/balance'];
            },
            list() {
                return this.$store.getters['balanceDetails/requestList'];
            },
            loading() {
                return this.$store.state.balanceDetails.loading;
            },
        }
    };
</script>
<style lang="scss" scoped>
    .balance{
        font-size: 16px;
        span.amount{
            font-size: 30px;
        }
    }

    .leaderboard table img {
        max-width: 200px;
    }

    table, thead, tbody {
        width: 100%;

        th, td{
            font-size: 14px;
            text-align: center;
        }
    }

    .badge{
        color: #ffffff;
        font-size: 14px;
    }
    .leaderboard__info {
      width: 30px;
      height: 30px;
      text-align: center;
      align-items: center;
      line-height: 36px;
      border-radius: 50% 50%;
      display: inline-block;
      padding: .75rem 1.25rem;
      color: #fff;
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: .15rem;
      transition: all .3s;
      position: relative;
      overflow: hidden;
      z-index: 1;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #495057;
        border-radius: 10rem;
        z-index: -2;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken(#556ee6, 15%);
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
      }
      &:hover {
        color: #fff;
        &:before {
          width: 100%;
        }
      }
    }
</style>